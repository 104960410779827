import React, { useState, useRef } from "react";

import Slider from "react-slick";
import {Link} from "react-router-dom"
import testimprofile from "../images/testimonialimg1.jpg";
// import GOCrev from "../media/images/company-logo/Ts-rev1.jpg";
// import Flprev from "../media/images/company-logo/Ts-rev2.jpg";
// import GMSrev from "../media/images/company-logo/gms-rev3.jpg";
// import Repairrev from "../media/images/company-logo/repair-buddy-rev4.jpg";
import GOCrev from "../media/images/company-logo/GOC.png";
import Flprev from "../media/images/company-logo/FT.jpg";
import GMSrev from "../media/images/company-logo/GMS.jpg";
import Repairrev from "../media/images/company-logo/RB.jpeg";

function TestimonialsReviews() {
  const [oldSlide, setOldSlide] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);

  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    arrows: false,

    beforeChange: (current, next) => {
      setOldSlide(current);
      setActiveSlide(next);
    },
    afterChange: (current) => setActiveSlide2(current),
  };

  return (
    <div className="testimonial-review-background-container">
      <div className="main-testimonial-review-container">
        <Slider ref={sliderRef} {...settings}>
          <div className="inner-testimonial-review-container">
            <div>
              <img src={GOCrev}></img>
            </div>

            <div>
              BHUMU has been a game changer for our business, Garage On Call –
              Bike and Scooty Service and Repair at Doorstep. Their expert
              digital marketing strategies boosted our online presence and
              customer engagement. Highly recommended!
              <div className="ts-review-username">
              <Link to="">Garage On Call  </Link> <span>Our Customer</span>
              </div>
            </div>

            {/* <div>hellow</div> */}
          </div>

          <div className="inner-testimonial-review-container">
            <div>
              <img src={Flprev}></img>
            </div>

            <div>
              BHUMU has greatly helped Fluffy Touch – Dog and Cat Grooming &
              Designer Haircut Services at Doorstep by enhancing our online
              reach. Their marketing expertise brought us more customers. Highly
              recommended 🐶🐱
              <div className="ts-review-username">
              <Link to="">   Fluffy Touch </Link><span>Our Customer</span>
              </div>
            </div>

            {/* <div>hellow</div> */}
          </div>

          <div className="inner-testimonial-review-container">
            <div>
              <img src={GMSrev}></img>
            </div>

            <div>
              Highly Recommended! BHUMU has helped Glazma reach more customers
              with their excellent digital marketing services. Their expertise
              in Google Ads and online presence has boosted our business. Great
              team and support. Glazma - Male Waxing Services at Doorstep
              <div className="ts-review-username">
               <Link to="">  Glazma </Link><span>Our Customer</span>
              </div>
            </div>

            {/* <div>hellow</div> */}
          </div>

          <div className="inner-testimonial-review-container">
            <div>
              <img src={Repairrev}></img>
            </div>

            <div>
              Amazing Service! BHUMU has been instrumental in growing our
              business with their professional Google Ads and online marketing
              strategies. Their support has increased our customer base and
              visibility. Highly recommend! Repairbuddy - AC
              Services at Doorstep
              <div className="ts-review-username">
               <Link to="">  Repairbuddy</Link> <span>Our Customer</span>
              </div>
            </div>

            {/* <div>hellow</div> */}
          </div>
        </Slider>

        <div className="ts-slide-customer-arrow">
          <div>
            {/* <img src={testimprofile}></img>
              <img src={testimprofile}></img>
              <img src={testimprofile}></img> */}
          </div>
          <div>
            <div  onClick={() => sliderRef.current.slickPrev()}>
            {/* <div  onClick={() => sliderRef.current.slickNext()}> */}
              <i
              
                class="fa-solid fa-arrow-left"
              ></i>{" "}
            </div>
            <div onClick={() => sliderRef.current.slickNext()}>
            {/* <div  onClick={() => sliderRef.current.slickPrev()}> */}
              <i
              
                class="fa-solid fa-arrow-right"
              ></i>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialsReviews;
