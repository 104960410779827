import React from 'react'
import Navbar from '../components/common/Navbar'
import TopNavbar from '../components/common/TopNavbar'
import HomeHeader from '../components/home/HomeHeader'
import Footer from '../components/common/Footer'
import Digitalcard from '../components/common/Digitalcard'
import BestAgencycard from "../components/common/BestAgencycard"
import AgencyTagImageContainer from "../components/common/AgencyTagImageContainer"
import DigitalFAQ from '../components/common/DigitalFAQ'
import SuccesTeamCard from '../components/common/SuccesTeamCard'
import BussnessSuccessShow from "../components/common/BussnessSuccessShow"
import ExploreBestProject from '../components/common/ExploreBestProject'
import UserReviewCard from '../components/common/UserReviewCard'

import LatestArtical from '../components/common/LatestArtical'
import HomeSliderEffect from '../newpages/HomeSliderEffect'
import ShowProjectData from '../newpages/ShowProjectData'
import ServiceMarque from '../newpages/ServiceMarque'
import MarketingScrollTag from '../newpages/MarketingScrollTag'
import TestimonialsReviews from '../newpages/TestimonialsReviews'
import OurPatnersLogo from '../newpages/OurPatnersLogo'
import GetInTouch from '../newpages/GetInTouch'
import WhyusHome from '../newpages/WhyusHome'
import BussnessTag from '../components/common/BussnessTag'


const HomePage = () => {
  return (
    <div>
      <div className="home-page">
        {/* <TopNavbar />
        <div className="home-page-nav">
          <Navbar />
        </div> */}

        <div className="home-page-header">
        <HomeSliderEffect/>
          {/* <HomeHeader /> */}
        </div>

        {/* <ServiceMarque/> */}

     
        
        <Digitalcard/>

        <ShowProjectData/>

        <BestAgencycard/>

        {/* <GetInTouch/> */}
        <OurPatnersLogo/>

      
        <AgencyTagImageContainer/>
        <DigitalFAQ/>

      {/* <SuccesTeamCard /> */}
      <BussnessSuccessShow />
           {/* <BussnessTag /> */}

        {/* <MarketingScrollTag/> */}
        <WhyusHome/>
     
      <ExploreBestProject />
      <TestimonialsReviews/>
      {/* <UserReviewCard/> */}
      <LatestArtical/> 
    




        {/* <Footer /> */}
      </div>
    </div>
  )
}

export default HomePage
