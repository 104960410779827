import React, { useState } from 'react'

const TopHomebutton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
                in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);
    
  return (
    <div>
        <div  >
            <button className="home-top-button" onClick={scrollToTop} style={{ display: visible ? 'inline' : 'none' }}> 
            <i class="fa-solid fa-arrow-up"></i>
            </button>

        {/* <i className="fa-solid fa-chevron-up"></i> */}
        {/* <i className="fa-solid fa-caret-up"></i> */}
        {/* <i className="fa-solid fa-arrow-up"></i> */}
        {/* <i className="fa-solid fa-chevron-up"></i> */}
        {/* <i className="fa-solid fa-up-long"></i> */}
        </div>
    </div>
  )
}

export default TopHomebutton