import React, { useEffect } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import "./App.css";
import "./css/common/TopNavbar.css";
import "./css/common/Navbar.css";
import "./css/home/HomePage.css";
import "./css/home/HomeHeader.css";
import "./css/common/Footer.css";
import "./css/common/Animation.css";
import "./css/common/Animation.css";
import "./css/common/Digitalcard.css";
import "./css/common/bestagencyCard.css";
import "./css/common/bussnessSuccessShow.css";
import "./css/common/bussnessTag.css";
import "./css/common/DigitalFAQ.css";
import "./css/common/agencyTagimageContainer.css";
import "./css/common/SuccesTeamCard.css";
import "./css/common/exploreBestproject.css";
import "./css/common/UserReviewCard.css";
import "./css/common/latestArtical.css";
import "./css/home/ContactusPage.css";
import "./css/home/MobileApplicationsPages.css";
import "./css/common/BreadCrum.css";
import "./css/home/ProjectsPages.css";
import "./css/home/PricingTables.css";
import "./css/home/ServicesPage.css";
import "./css/home/OurTeamPage.css"
import "./css/home/ProjectDetailsPage.css"
import "./css/home/ErrorPage.css"
import "./css/home/TeamDetailsPage.css"
import "./css/home/AboutPage.css"
import "./css/home/FaqPage.css"
import "./css/home/BlogPage.css"
import "./css/home/BlogDetailsPage.css"
import "./css/common/BackgroundAnimation.css"
import "./css/common/HomeBgAnimation.css"
import "./css/common/Alert.css"
import "./css/common/ShowPop.css"


// import new page CSS  start

import "./css/newhome/HomeSliderEffect.css"
import "./css/newhome/ShowProjectData.css"
import "./css/newhome/ServiceMarque.css"
import "./css/newhome/MarketingScrollTag.css"
import "./css/newhome/FooterNew.css"
import "./css/newhome/TestimonialsReviews.css"
import "./css/newhome/OurPatnersLogo.css"
import  "./css/newhome/GetInTouch.css"
import "./css/newhome/Footerpolicy.css"
import "./css/newhome/WhyusHome.css"
// import new page CSS  end


import ContactusPage from "./pages/ContactusPage";

import MobileApplicationPages from "./components/servicepage/MobileApplicationsPages"
import DigitalMarketingPages from "./components/servicepage/DigitalMarketingPages";
import GraphicDesigning from "./pages/GraphicDesigning";
import SocialMarketingPage from "./pages/SocialMarketingPage";
import WebDevelopmentPage from "./pages/WebDevelopmentPage";
import ProjecsPage from "./pages/ProjecsPage";
import PricingTables from "./pages/PricingTables";
import ServicesPage from "./pages/ServicesPage";
import Navbar from "./components/common/Navbar";
import TopNavbar from "./components/common/TopNavbar";
import Footer from "./components/common/Footer";
import ContentWriting from "./pages/ContentWriting";
import OurTeamPage from "./pages/OurTeamPage";
import BlogPage from "./pages/BlogPage";
import ProjectDetailsPage from "./pages/ProjectDetailsPage";
import ErrorPage from "./pages/ErrorPage";
import ScrollTop from "./components/common/ScrollTop";
import TeamDetailsPage from "./pages/TeamDetailsPage";
import AboutPage from "./pages/AboutPage";
import FaqPage from "./pages/FaqPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import Test from "./components/common/Test";
import Alert from "./components/common/Alert";
import ShowPop from "./components/common/ShowPop";
import FooterNew from "./newpages/FooterNew";
import Footerpolicy from "./newpages/Footerpolicy";
import TopHomebutton from "./newpages/TopHomebutton";
import CpanelPage from "./components/others/CpanelPage";
import SmallBusnessBlog1 from "./components/blogpages/SmallBusnessBlog1";
import SEOBlog2 from "./components/blogpages/SEOBlog2";
import AdsBlog3 from "./components/blogpages/AdsBlog3";



// import "./css/common/"

function App() {


  return (
    <div className="web-main">
      <BrowserRouter>
      <ScrollTop/>
        <TopNavbar />

        <div className="web-nav">
          <Navbar />
        </div>

        <Routes>
        {/* <Route path="/alert" element={<Alert />} /> */}
        {/* <Route path="/pop" element={<ShowPop/>} /> */}

          <Route path="/" element={<HomePage />} />

          {/* Service pages start */}
          {/* <Route path="/mobile-service" element={<MobileApplicationPages />} />


          <Route
            path="/digital-marketing"  element={<DigitalMarketingPages />}
          /> */}
             {/* Service pages end */}

          {/* <Route path="/contact-us" element={<ContactusPage />} />
          <Route path="/services" element={<ServicesPage />} />

          <Route path="/mobile-service" element={<MobileApplicationPages />} />
          
          <Route path="/web-application" element={<WebDevelopmentPage />} />
          <Route path="/social-marketing" element={<SocialMarketingPage />} />
          <Route path="/graphics-design" element={<GraphicDesigning />} />
          <Route path="/content-writing" element={<ContentWriting />} />
          <Route path="/projects" element={<ProjecsPage />} />
          <Route path="/pricingtables" element={<PricingTables />} />
          <Route path="/our-team" element={<OurTeamPage/>} />
          <Route path="/blog" element={<BlogPage/>} />
          <Route path="/project-details" element={<ProjectDetailsPage/>}/>
          <Route path="/team-details" element={<TeamDetailsPage/>}/>
          <Route  path="/faq" element={<FaqPage/>} />
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/blog-details" element={<BlogDetailsPage/>}/>
                    <Route path="/pricingtables" element={<PricingTables />} />

          */}

          {/* Blog Pages Url start */}
          <Route path="/business-blog" element={<SmallBusnessBlog1/>}/>
          <Route path="/blog-details" element={<BlogDetailsPage/>}/>
          <Route path="/seo-blog"  element={ <SEOBlog2/>}  />
          <Route path="/ads-blog"  element={ <AdsBlog3/>}  />

           {/* Blog Pages Url end */}
          

          <Route path="*" element={<ErrorPage/>}/>
          <Route path="/cpanel" element={<CpanelPage/>}></Route>
          
          
          {/* other / test  */}
          {/* <Route path="/test" element={<Test/>}/> */}
          
        </Routes>

<FooterNew/>
<Footerpolicy/>
<TopHomebutton/>
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
