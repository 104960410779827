import React, { useState } from "react";
import Slider from "react-slick";

import { Link } from "react-router-dom";
import righttop from "./../images/rightsliderimg.png"
import rightbottom from "./../images/mainrightdown.png"
import lefthomeimg from "./../images/mainleft.png"
import homesm from "./../images/homesliders.png"


function HomeSliderEffect() {

  const [active , setactive] = useState(0)
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5000,
    rtl: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  return (
    <div className="hhhhhhh">
      <div className="slider-container">
        <Slider {...settings}>


          <div className="slider-effect-bgimg-container">
            <div className="slider-effect-img-left-container">
              <div className="slider-effect-bg-shaow-container">
                <div className="slider-effect-img-right-container">
                  <div className="slider-top-left-image">
                    <img src={lefthomeimg} />
                  </div>

                  <div className="slider-top-right-image">
                    <img
                      className="image-right-top"
                      src={righttop}
                    />
                    <img
                      className="image-right-bootom"

                      src={rightbottom}
                    />
                  </div>
                  <div className="slider-top-text-content">

                    <div className="slider-effct-animation-1">
                    <div className="slider-top-text-content-heading-0">
                      Welcome to BHUMU Digital Agency
                    </div>
                    <div className="slider-top-text-content-heading-1">
                      Make Your <br />
                      Business{" "}
                      <span>
                        {" "}
                        Grow
                        <img 
                        src={homesm}
                        ></img>
                      </span>
                    </div>
                    </div>

                    <div className="slider-top-text-content-heading-2"></div>

                    <div className="circle-text-container">
                      <Link to="/">
                        <svg viewBox="0 0 200 200">
                          <defs>
                            <path
                              id="circlePath"
                              d="M 100, 100 m -75, 0 a 75,75 0 1,1 150,0 a 75,75 0 1,1 -150,0"
                            />
                          </defs>
                          <text className="circle-text">
                            <textPath href="#circlePath">
                              DISCOVER MORE to BHUMU Digital Services
                            </textPath>
                          </text>
                        </svg>

                        <div className="center-icon">
                          <i class="fa-solid fa-arrow-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="slider-effect-bgimg-container">
            <div className="slider-effect-img-left-container">
              <div className="slider-effect-bg-shaow-container">
                <div className="slider-effect-img-right-container">
                  <div className="slider-top-left-image">
                    <img src={lefthomeimg} />
                  </div>

                  <div className="slider-top-right-image">
                    <img
                      className="image-right-top"
                      src={righttop}
                    />
                    <img
                      className="image-right-bootom"

                      src={rightbottom}
                    />
                  </div>
                  <div className="slider-top-text-content">

                    <div className="slider-effct-animation-1">
                    <div className="slider-top-text-content-heading-0">
                      Welcome to BHUMU Digital Agency
                    </div>
                    <div className="slider-top-text-content-heading-1">
                      Make Your <br />
                      Business{" "}
                      <span>
                        {" "}
                        Grow
                        <img 
                        src={homesm}
                        ></img>
                      </span>
                    </div>
                    </div>

                    <div className="slider-top-text-content-heading-2"></div>

                    <div className="circle-text-container">
                      <Link to="/">
                        <svg viewBox="0 0 200 200">
                          <defs>
                            <path
                              id="circlePath"
                              d="M 100, 100 m -75, 0 a 75,75 0 1,1 150,0 a 75,75 0 1,1 -150,0"
                            />
                          </defs>
                          <text className="circle-text">
                            <textPath href="#circlePath">
                              DISCOVER MORE to BHUMU Digital Services
                            </textPath>
                          </text>
                        </svg>

                        <div className="center-icon">
                          <i class="fa-solid fa-arrow-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="slider-effect-bgimg-container">
            <div className="slider-effect-img-left-container">
              <div className="slider-effect-bg-shaow-container">
                <div className="slider-effect-img-right-container">
                  <div className="slider-top-left-image">
                    <img src={lefthomeimg} />
                  </div>

                  <div className="slider-top-right-image">
                    <img
                      className="image-right-top"
                      src={righttop}
                    />
                    <img
                      className="image-right-bootom"

                      src={rightbottom}
                    />
                  </div>
                  <div className="slider-top-text-content">

                    <div className="slider-effct-animation-1">
                    <div className="slider-top-text-content-heading-0">
                      Welcome to BHUMU Digital Agency
                    </div>
                    <div className="slider-top-text-content-heading-1">
                      Make Your <br />
                      Business{" "}
                      <span>
                        {" "}
                        Grow
                        <img 
                        src={homesm}
                        ></img>
                      </span>
                    </div>
                    </div>

                    <div className="slider-top-text-content-heading-2"></div>

                    <div className="circle-text-container">
                      <Link to="/">
                        <svg viewBox="0 0 200 200">
                          <defs>
                            <path
                              id="circlePath"
                              d="M 100, 100 m -75, 0 a 75,75 0 1,1 150,0 a 75,75 0 1,1 -150,0"
                            />
                          </defs>
                          <text className="circle-text">
                            <textPath href="#circlePath">
                              DISCOVER MORE to BHUMU Digital Services
                            </textPath>
                          </text>
                        </svg>

                        <div className="center-icon">
                          <i class="fa-solid fa-arrow-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="slider-effect-bgimg-container">
            <div className="slider-effect-img-left-container">
              <div className="slider-effect-bg-shaow-container">
                <div className="slider-effect-img-right-container">
                  <div className="slider-top-left-image">
                    <img src={lefthomeimg} />
                  </div>

                  <div className="slider-top-right-image">
                    <img
                      className="image-right-top"
                      src={righttop}
                    />
                    <img
                      className="image-right-bootom"

                      src={rightbottom}
                    />
                  </div>
                  <div className="slider-top-text-content">

                    <div className="slider-effct-animation-1">
                    <div className="slider-top-text-content-heading-0">
                      Welcome to BHUMU Digital Agency
                    </div>
                    <div className="slider-top-text-content-heading-1">
                      Make Your <br />
                      Business{" "}
                      <span>
                        {" "}
                        Grow
                        <img 
                        src={homesm}
                        ></img>
                      </span>
                    </div>
                    </div>

                    <div className="slider-top-text-content-heading-2"></div>

                    <div className="circle-text-container">
                      <Link to="/">
                        <svg viewBox="0 0 200 200">
                          <defs>
                            <path
                              id="circlePath"
                              d="M 100, 100 m -75, 0 a 75,75 0 1,1 150,0 a 75,75 0 1,1 -150,0"
                            />
                          </defs>
                          <text className="circle-text">
                            <textPath href="#circlePath">
                              DISCOVER MORE to BHUMU Digital Services
                            </textPath>
                          </text>
                        </svg>

                        <div className="center-icon">
                          <i class="fa-solid fa-arrow-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="slider-effect-bgimg-container">
            <div className="slider-effect-img-left-container">
              <div className="slider-effect-bg-shaow-container">
                <div className="slider-effect-img-right-container">
                  <div className="slider-top-left-image">
                    <img src={lefthomeimg} />
                  </div>

                  <div className="slider-top-right-image">
                    <img
                      className="image-right-top"
                      src={righttop}
                    />
                    <img
                      className="image-right-bootom"

                      src={rightbottom}
                    />
                  </div>
                  <div className="slider-top-text-content">

                    <div className="slider-effct-animation-1">
                    <div className="slider-top-text-content-heading-0">
                      Welcome to BHUMU Digital Agency
                    </div>
                    <div className="slider-top-text-content-heading-1">
                      Make Your <br />
                      Business{" "}
                      <span>
                        {" "}
                        Grow
                        <img 
                        src={homesm}
                        ></img>
                      </span>
                    </div>
                    </div>

                    <div className="slider-top-text-content-heading-2"></div>

                    <div className="circle-text-container">
                      <Link to="/">
                        <svg viewBox="0 0 200 200">
                          <defs>
                            <path
                              id="circlePath"
                              d="M 100, 100 m -75, 0 a 75,75 0 1,1 150,0 a 75,75 0 1,1 -150,0"
                            />
                          </defs>
                          <text className="circle-text">
                            <textPath href="#circlePath">
                              DISCOVER MORE to BHUMU Digital Services
                            </textPath>
                          </text>
                        </svg>

                        <div className="center-icon">
                          <i class="fa-solid fa-arrow-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="slider-effect-bgimg-container">
            <div className="slider-effect-img-left-container">
              <div className="slider-effect-bg-shaow-container">
                <div className="slider-effect-img-right-container">
                  <div className="slider-top-left-image">
                    <img src={lefthomeimg} />
                  </div>

                  <div className="slider-top-right-image">
                    <img
                      className="image-right-top"
                      src={righttop}
                    />
                    <img
                      className="image-right-bootom"

                      src={rightbottom}
                    />
                  </div>
                  <div className="slider-top-text-content">

                    <div className="slider-effct-animation-1">
                    <div className="slider-top-text-content-heading-0">
                      Welcome to BHUMU Digital Agency
                    </div>
                    <div className="slider-top-text-content-heading-1">
                      Make Your <br />
                      Business{" "}
                      <span>
                        {" "}
                        Grow
                        <img 
                        src={homesm}
                        ></img>
                      </span>
                    </div>
                    </div>

                    <div className="slider-top-text-content-heading-2"></div>

                    <div className="circle-text-container">
                      <Link to="/">
                        <svg viewBox="0 0 200 200">
                          <defs>
                            <path
                              id="circlePath"
                              d="M 100, 100 m -75, 0 a 75,75 0 1,1 150,0 a 75,75 0 1,1 -150,0"
                            />
                          </defs>
                          <text className="circle-text">
                            <textPath href="#circlePath">
                              DISCOVER MORE to BHUMU Digital Services
                            </textPath>
                          </text>
                        </svg>

                        <div className="center-icon">
                          <i class="fa-solid fa-arrow-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          
        </Slider>
      </div>
    </div>
  );
}

export default HomeSliderEffect;
