import React, { useState } from "react";

function FaqDataPage() {
  const FaqData = [
    {
      Question: "What services does BHUMU offer?",
      Answer: "We specialize in Google Ads, Meta Ads, SEO, website development, app development, and video production to help businesses grow their online presence.",
    },
    {
      Question: "How can BHUMU help improve my business's online visibility?",
      Answer: "Our SEO, PPC, and social media marketing strategies ensure that your brand gets maximum exposure to the right audience."
    },
    {
      Question: "What makes BHUMU the best digital marketing agency?",
      Answer: "BHUMU stands out due to its data-driven strategies, expert team, and proven success rate in delivering exceptional results for clients across various industries."
    },
    {
      Question: "How can Google Ads help my business?",
      Answer: "Google Ads helps your business gain instant visibility on search engines, drive qualified traffic, and increase conversion rates through targeted ad campaigns."
    },
    {
      Question: "Do you offer SEO services for local businesses?",
      Answer:"Yes, we provide Local SEO services to help businesses rank higher in Google My Business (GMB) and local search results."
    },

    {
      Question: " What industries do you serve?",
      Answer:"We work with eCommerce, healthcare, real estate, education, finance, startups, and more, providing industry-specific digital marketing solutions."
    },
    {
      Question: "How long does it take to see results from SEO?",
      Answer:"SEO is a long-term investment. You may start seeing initial improvements in 3-6 months, while significant results typically take 6-12 months depending on competition and strategy."
    },

    {
      Question: "Can you create custom websites for my business?",
      Answer:"Absolutely! We specialize in custom web development, ensuring your site is tailored to your brand identity and business goals."
    },


    {
      Question: "Do you offer mobile app development?",
      Answer:"Yes! We create custom Android & iOS apps that enhance user experience and drive engagement for businesses."
    },

    {
      Question: "How can I get started with BHUMU?",
      Answer:"Getting started is easy! Simply contact us via phone, email, or our website, and our experts will guide you through the process."
    },
  ];

  const [viewAnswer, setViewAnswer] = useState(0);

  return (
    <>
      {/* map start -------------------------------------------------------------------------------- */}

      {FaqData.map((e, i) => (
        <div className="details-right-digital-faq-container">
          <div className="details-right-digital-faq-question">
            {e.Question}

            {viewAnswer === i ? (
              <i   onClick={() => setViewAnswer(null) } class="fa-solid fa-minus"></i>
            ) : (
              <i onClick={() => setViewAnswer(i)} class="fa-solid fa-plus"></i>
            )}
          </div>

          {viewAnswer === i ? (
            <div className="details-right-digital-faq-answer">
           {e.Answer}
            </div>
          ) : (
            ""
          )}
        </div>
      ))}

      {/* map end ---------------------------------------------------------------------------------- */}
    </>
  );
}

export default FaqDataPage;
