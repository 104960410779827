import React from "react";
import Slider from "react-slick";

import grazonlogo from ".././images/grazoncalllogo.png";
import flupytouchlogo from ".././images/fullpy-touch-logo.png";
import glazmalogo from ".././images/glazma-logo.png";
import repairbuddy from ".././images/repairboddylogo.jpeg";
import legendslogo from ".././images/LOL-Legends-Black-logo.png";
import tatoovalilogo from ".././images/tatoovali-logo.png";
import feedbackerlogo from ".././images/newfeedbacker-logo.png";

import GOC from "../media/images/company-logo/GOC.png"
import FT from "../media/images/company-logo/FT.jpg"
import GMS from "../media/images/company-logo/GMS.jpg"
import RB from "../media/images/company-logo/RB.jpeg"
import LOL from "../media/images/company-logo/LOL.png"
import FB from "../media/images/company-logo/FB.png"
import TW from "../media/images/company-logo/TW.png"



function OurPatnersLogo() {
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    swipeToSlide: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    adaptiveHeight: true,
    variableWidth: true,

    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
    responsive: [
      {
        breakpoint: 768, // Tablet
        settings: {
          slidesToShow: 3,
          centerPadding: "40px",
        },
      },
    ],
  };
  return (
    <div className="our-patnerslogo-main-container">
      <div className="our-patnerslogo-inner-container">
        <div className="our-patnerslogo-heading">
          <div class="patner-logo-line"></div>
          <p class="patner-logo-title">OUR PARTNERS & SUPPORTERS</p>
          <div class="patner-logo-line"></div>
        </div>

        <div className="our-patners-logo-data">
          <Slider {...settings}>


            <div className="our-patner-logo-container">
              <img src={GOC} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={FT} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={GMS} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={RB} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={FB} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={LOL} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={TW} alt="company logo" />
            </div>




{/* Duplicate */}

<div className="our-patner-logo-container">
              <img src={GOC} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={FT} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={GMS} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={RB} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={FB} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={LOL} alt="company logo" />
            </div>


            <div className="our-patner-logo-container">
              <img src={TW} alt="company logo" />
            </div>





            
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default OurPatnersLogo;
