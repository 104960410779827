import React from "react";
// import "../CSS/bussnessTag.css"
function BussnessTag() {
  return (
    <div className="OOuter-bussnessTag-container">
      <div className="bussnessTag-container">
        <div className="hexagon1">Business Growth</div>
        <div className="hexagon2">
          <div className="bussnessTag-container-shape-left"> </div>
          Financial Advice
          <div className="bussnessTag-container-shape-right"></div>
        </div>
        <div className="hexagon3">Marketing Solution</div>
      </div>
    </div>
  );
}

export default BussnessTag;
