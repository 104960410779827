import React from "react";
import { Link } from "react-router-dom";
function TopNavbar() {
  return (
    <>
      <div className="top-nav-main-container">
        <div className="top-nav-inner-container">
          <div className="top-nav-left">
            <Link to="">
              <i class="fa-solid fa-envelope"></i> support@bhumu.com
            </Link>

            <Link to="">
              <i class="fa-solid fa-location-pin"></i> 210, 2nd floor, Ocean Plaza, Sector 18 Noida 201301
            </Link>
          </div>

          <div className="top-nav-right">
            <div className="top-text">
              <Link to="">About</Link>
              <span>/</span>
              <Link to="">Help</Link>
              <span>/</span>
              <Link to="">Contact</Link>
            </div>

            {/* <span>|</span> */}

            <div className="top-icons-group">
              <div>Follow on:</div>

              
               <>
              
                              <Link target="_blank" title="social media link" to="https://www.instagram.com/Bhumugroup/"><i className="fa-brands fa-instagram"></i></Link>
                              <Link target="_blank" title="social media link" to="https://www.youtube.com/channel/UCeCCGpdtQ4TLKz8NAMkgR4A"><i className="fa-brands fa-youtube"></i></Link>
                              <Link target="_blank" title="social media link" to="https://www.facebook.com/bhumugroup"><i className="fa-brands fa-facebook-f"></i></Link>
                              <Link target="_blank" title="social media link" to="https://www.linkedin.com/company/bhumu-group-of-companies/?viewAsMember=true"><i className="fa-brands fa-linkedin-in"></i></Link>
                              <Link target="_blank" title="social media link" to="https://twitter.com/GroupBhumu"><i className="fa-brands fa-x-twitter"></i></Link>
                              
                              </>
              
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopNavbar;
