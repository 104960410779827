import React, { useState } from "react";
import FaqDataPage from "./FaqDataPage";
import CountUp from "react-countup";
import projectIcon from "../../media/images/icons/ServiceIcon4.png"
// import {useState} from "react";
const DigitalFAQ = () => {
  return (
    <div className="digital-mian-faq">
      <div className="inner-digital-mian-faq">
        <div className="details-left-top-digital-faq">
      
      <p className="p1-text">frequently asked questions
      </p>
          <p className="head">Questions & Answers</p>
          <p className="desc">
          Find answers to common queries about our digital marketing, SEO, Google Ads, web development, and app development services. We ensure expert solutions, high ROI, and seamless business growth.

          </p>

          <div className="inner-details-left-digital-faq-lift">
            <div>
              <p>
                <i class="fa-solid fa-check "></i> Expert in Google Ads, SEO, and web development 
              </p>

              <p>
                {" "}
                <i class="fa-solid fa-check"></i>Proven strategies for business growth 

              </p>

              <p>
                {" "}
                <i class="fa-solid fa-check"></i>Serving various industries 

              </p>

              <p>
                {" "}
                <i class="fa-solid fa-check"></i> High ROI advertising solutions 

              
              </p>

              <p>
                {" "}
                <i class="fa-solid fa-check"></i> Custom web & app development 

              </p>


              <p>
                {" "}
                <i class="fa-solid fa-check"></i> Easy onboarding & support

              </p>
            </div>
            <div>
              <div className="client-project-slider-contaier">
                <div className="inner-client-project-slider-contaier">

                  <div className="Project-iconnss">
                 

                    <img src={projectIcon}></img>
                  </div>
                  <div>

                    <p><CountUp end={1500}  enableScrollSpy/></p>
                    <p>Projects finished</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="details-right-digital-faq">
          {/* map start ---------------------------------------------------------------------------------- */}

          {/* {
  FaqData.map((e,i)=>(

          <div className="details-right-digital-faq-container">

            <div className="details-right-digital-faq-question">
              {e.Question}
             


              {
                viewAnswer === i ?
                
                <i class="fa-solid fa-minus"></i>
                
                :
                
                <i onClick={()=>setViewAnswer(i)} class="fa-solid fa-plus"></i>
              }
              
            


            </div>


            {
             viewAnswer === i ?
             
             
            
            <div className="details-right-digital-faq-answer">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vitae id
              impedit optio consequatur sapiente? Id dolores optio ad.
              Consectetur, et?
            </div>
            :""

            }
            
            
          </div>

  ))
} */}

          <FaqDataPage />

          {/* map end ---------------------------------------------------------------------------------- */}
        </div>
      </div>
    </div>
  );
};

export default DigitalFAQ;
