import React from "react";

function Alert({ setPopupAlert }) {
  return (
    <div className="alert-main-container">
      <div className="inner-alert-main-container">
        <div className="alert-container">
          <div>
            <i class="fa-solid fa-triangle-exclamation"></i>
          </div>
          <div>
            <p>Under Maintenance </p>

            <p>We building something new for you   </p>
            <p>
              <button  onClick={()=>setPopupAlert(false)}> Continue <i class="fa-solid fa-circle-arrow-right"></i></button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alert;
