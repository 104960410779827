import React from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import sevIcon1 from "../../media/images/icons/SV1.png"
import sevIcon2 from "../../media/images/icons/SV2.png"
import sevIcon3 from "../../media/images/icons/SV3.png"
import sevIcon4 from "../../media/images/icons/SV4.png"
import sevIcon5 from "../../media/images/icons/SV5.png"

function DigitalCard1() {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.0,
  });

  return (
    <div className="digitalcard-main-container">
      <div className="digitalcard-inner-container">
        <div className="inner-card-of-marketing">
          <div class="inner-card-of-marketing2">
            <Link to="/mobile-service">
              <div class="card">
                <div className="icon">

                  <img src={sevIcon3}></img>
                  {/* <i class="fa-solid fa-mobile-screen-button"></i> */}
                </div>
                <div class="title">Mobile Applications</div>
              
              </div>
              <div className="plus">
                  {" "}
                  <i class="fa-solid fa-plus"></i>
                </div>
            </Link>
          </div>

          <div class="inner-card-of-marketing2">
            <Link to="/digital-marketing">
              <div class="card">
                <div class="icon">
                <img src={sevIcon4}></img>
                </div>
                <div class="title">Digital Marketing </div>
               
              </div>
              <div className="plus">
                  {" "}
                  <i class="fa-solid fa-plus"></i>
                </div>
            </Link>
          </div>

          <div class="inner-card-of-marketing2">
            <Link to="/graphics-design">
              <div class="card">
                <div class="icon">
                <img src={sevIcon1}></img>
                </div>
                <div class="title">
                  Graphics  Designing
                </div>
            
              </div>
              <div className="plus">
                  {" "}
                  <i class="fa-solid fa-plus"></i>
                </div>
            </Link>
          </div>

          <div class="inner-card-of-marketing2">
            <Link to="/web-application">
              <div class="card">
                <div className="icon">
                <img src={sevIcon5}></img>
                </div>
                <div class="title">
                  Web  Development
                </div>
              
              </div>
              <div className="plus">
                  {" "}
                  <i class="fa-solid fa-plus"></i>
                </div>
            </Link>
          </div>

          <div className="inner-card-of-marketing2">
            <Link to="/social-marketing">


              <div className="card">


                <div className="icon">
                <img src={sevIcon2}></img>
                </div>


                <div className="title">
                  Social Media 
                  Marketing
                </div>


             


              </div>

              <div className="plus">
                  {" "}
                  <i class="fa-solid fa-plus"></i>
                </div>
            </Link>
          </div>
        </div>

        <div className="card-botton-2">
          <p
            ref={ref}
            className={`
        
                   ${
                     inView
                       ? "fade-up-fade-upOneS"
                       : "fade-up-animation-opacity"
                   }`}
          >
            <div className="card-bottom-2-icon-container">
              <div className="card-profile1"> </div>
              <div className="card-profile2"></div>
              <div className="card-profile3"></div>
            </div>

            <div>
              We’ve 90% satisfied customers with our services. Let’s Get
              Started
              <Link> Let’s Get Started </Link>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default DigitalCard1;
