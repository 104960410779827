import React from "react";
import CountUp from "react-countup";
import SevIcon1 from "../media/images/icons/ServiceIcon1.png"
import SevIcon2 from "../media/images/icons/ServiceIcon2.png"
import SevIcon3 from "../media/images/icons/ServiceIcon3.png"
import SevIcon4 from "../media/images/icons/ServiceIcon4.png"

function ShowProjectData() {
  return (
    <div className="show-project-data-bg">
      <div className="show-project-data-container">
        <div className="inner-card-show-project-data">
          <div className="card-show-project-data">

            <div className="inner-card-show-project-data1">
               <div>  <img src={SevIcon4} /></div>
               <div>
                <p><CountUp end={1500} enableScrollSpy/></p>
                <p> Project Completed
                </p>
               </div>
               
            </div>
            <div className="inner-card-show-project-data1">
            <div>  <img src={SevIcon1} /></div>
               <div>
                <p><CountUp end={90} enableScrollSpy/> %</p>
                <p> Satisfied Customers</p>
               </div>
               
            </div>
            <div className="inner-card-show-project-data1">
            <div>  <img src={SevIcon2} /></div>
               <div>
                <p><CountUp end={85} enableScrollSpy/>%</p>
                <p> Reapeat Customers</p>
               </div>
               
            </div>
            <div className="inner-card-show-project-data1">
            <div>  <img src={SevIcon3} /></div>
               <div>
                <p><CountUp end={25} enableScrollSpy/></p>
                <p>Professional Team</p>
               </div>
               
            </div>

            
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowProjectData;
