import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "./Alert";
import bhumulogo from "../../images/bhumu log.png"

const Navbar = () => {
  const [isFixed, setIsFixed] = useState(false);

  const [popupAlert, setPopupAlert] = useState(false)

  const [viewMenu, setViewMenu] = useState(null);

  const [rotate, setrotate] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 140) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //-----------------------------------------------------------------------------------
  // Show Alert Pop -----------------------------------------------------
  const [alertVisible, setAlertVisible] = useState(false);

  const handleNavClick = ()=>setPopupAlert(true);

  //   ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  //   responsive nav::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  //   ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("navbar-small-responsive");
    setViewMenu(false);
  };

  //   ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <div>
      {/* <Alert/> */}
      {
        popupAlert ? (
          <Alert  setPopupAlert={setPopupAlert} />
        ):""
      }





      

      <div className={`navbar ${isFixed ? "navbar-fixed" : ""}`}>
        <div className="navbar-main">
          <div className="navbar-left-col">
            <div className="navbar-logo">
              <Link to="/">
              <img src={bhumulogo}></img>
              </Link>
            </div>

            {/* <div className="navbar-menu">
              <Link to="/">Home</Link>
              <Link to="/pricingtables">Pricing</Link>

              <div className="Drop-down">
                <Link to="/services">
                  Services <i class="fa-solid fa-caret-down"></i>
                </Link>
                <div className="Drop-down-list">
                  <Link to="/services">Services </Link>
                  <Link to="/mobile-service">Mobile Application</Link>
                  <Link to="/digital-marketing">Digital Marketing</Link>
                  <Link to="/graphics-design">Graphics Designing</Link>
                  <Link to="/web-application">Web Development</Link>
                  <Link to="/social-marketing">Social Marketing</Link>
                  <Link to="/content-writing">Seo & Content Writing</Link>
                </div>
              </div>

              <div className="Drop-down">
                <Link to="">
                  Company <i class="fa-solid fa-caret-down"></i>
                </Link>
                <div className="Drop-down-list">
                  <Link to="/about">About</Link>
                  <Link to="/our-team">Out Team</Link>
                  <Link to="/projects">Project</Link>
                  <Link to="/faq">FAQ</Link>
                </div>
              </div>

              <Link to="/blog">Blog</Link>

              <Link to="/contact-us">Contact</Link>
            </div> */}

            <div className="navbar-menu">
              <Link to="/">Home</Link>
              <Link onClick={handleNavClick}>Pricing</Link>
              <Link onClick={handleNavClick}>Services</Link>
              <Link onClick={handleNavClick}>Company</Link>
              <Link onClick={handleNavClick}>Blog</Link>
              <Link onClick={handleNavClick}>Contact</Link>
            </div>
          </div>

          <div className="navbar-right-col">
            <i class="fa-solid fa-phone-volume"></i>
            <div className="navbar-contact">
              <span>Call Helpline</span>
              <Link to="tel:9658-139-139" target="blank"  >
              +91 9658-139-139
              </Link>
            </div>
          </div>

          <div className="navbar-end">
            <Link to="tel:9658-139-139" target="blank"  >
              <i class="fa-solid fa-phone-volume"></i> +91 9658-139-139
            </Link>

            <i onClick={showNavbar} class="fa-solid fa-bars"></i>
          </div>
        </div>

        <div className="navbar-small" ref={navRef}>
          <div className="navbar-small-main">
            <div className="navbar-small-top">
              <Link to="/">
              <img src={bhumulogo}></img>
              </Link>
              <i onClick={showNavbar} class="fa-solid fa-xmark"></i>
            </div>
            <div className="navbar-small-menu">
              <Link onClick={handleNavClick} to="/">
                Home <i class="fa-solid fa-arrow-right"></i>
              </Link>


              <Link onClick={handleNavClick} to="">Pricing
                Pricing <i class="fa-solid fa-arrow-right"></i>
              </Link>

 
              {/* -----------------------changes code for alert  start*/}
              <Link onClick={handleNavClick} to="">
                Services <i class="fa-solid fa-arrow-right"></i>
              </Link>



              <Link onClick={handleNavClick} to="">
                Company <i class="fa-solid fa-arrow-right"></i>
              </Link>

              <Link onClick={handleNavClick} to="">
                Blog <i class="fa-solid fa-arrow-right"></i>
              </Link>

              {/* -----------------------changes code for alert  end */}

              {/* <div className="small-Drop-down">
                <Link
                  onClick={() =>
                    setViewMenu(viewMenu === "services" ? false : "services")
                  }
                >
                  Services{" "}
                  <i
                    className={`fa-solid fa-angle-right ${
                      viewMenu ? "rotate-icon-service" : false
                    }`}
                  ></i>
                </Link>
                {viewMenu === "services" ? (
                  <div className="small-Drop-down-list">
                    <Link onClick={showNavbar} to="/services">
                      Services{" "}
                    </Link>
                    <Link onClick={showNavbar} to="/mobile-service">
                      Mobile Application
                    </Link>
                    <Link onClick={showNavbar} to="/digital-marketing">
                      Digital Marketing
                    </Link>
                    <Link onClick={showNavbar} to="/graphics-design">
                      Graphics Designing
                    </Link>
                    <Link onClick={showNavbar} to="/web-application">
                      Web Development
                    </Link>
                    <Link onClick={showNavbar} to="/social-marketing">
                      Social Marketing
                    </Link>
                    <Link onClick={showNavbar} to="/content-writing">
                      Seo & Content Writing
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div> */}

              {/* <Link to="/projects">
                Company <i class="fa-solid fa-angle-right"></i>
              </Link> */}

              {/* <div className="small-Drop-down">
                <Link
                  onClick={() =>
                    setViewMenu(viewMenu === "company" ? false : "company")
                  }
                >
                  Company{" "}
                  <i
                    className={`fa-solid fa-angle-right ${
                      viewMenu ? "rotate-icon-company" : false
                    }`}
                  ></i>
                </Link>
                {viewMenu === "company" ? (
                  <div className="small-Drop-down-list">
                    <Link onClick={showNavbar} to="/about">
                      About
                    </Link>
                    <Link onClick={showNavbar} to="/our-team">
                      Out Team
                    </Link>
                    <Link onClick={showNavbar} to="/projects">
                      Project
                    </Link>
                    <Link onClick={showNavbar} to="/faq">
                      FAQ
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div> */}

              <Link onClick={handleNavClick} to="/contact-us">
                Contact <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </div>
            <div className="navbar-small-contact">
              <Link to="">
                <i class="fa-solid fa-envelope"></i> support@bhumu.com
              </Link>

              <Link to="tel:9658-139-139" target="blank"  >
                <i class="fa-solid fa-phone"></i> +91 9658-139-139
              </Link>
            </div>
            <div className="navbar-small-links">
               <>
              
                              <Link target="_blank" title="social media link" to="https://www.instagram.com/Bhumugroup/"><i className="fa-brands fa-instagram"></i></Link>
                              <Link target="_blank" title="social media link" to="https://www.youtube.com/channel/UCeCCGpdtQ4TLKz8NAMkgR4A"><i className="fa-brands fa-youtube"></i></Link>
                              <Link target="_blank" title="social media link" to="https://www.facebook.com/bhumugroup"><i className="fa-brands fa-facebook-f"></i></Link>
                              <Link target="_blank" title="social media link" to="https://www.linkedin.com/company/bhumu-group-of-companies/?viewAsMember=true"><i className="fa-brands fa-linkedin-in"></i></Link>
                              <Link target="_blank" title="social media link" to="https://twitter.com/GroupBhumu"><i className="fa-brands fa-x-twitter"></i></Link>
                              
                              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
