import React ,{useState}from "react";


import BussnessTag from "./BussnessTag";
import { useInView } from "react-intersection-observer";
import bussnessimh from  "../../images/why-choose-one-img-1.jpg"
function BussnessSuccessShow() {


  const { ref: ref1, inView: inView1} = useInView({
    triggerOnce: false,
    threshold: .0,
  });

  const { ref: ref2, inView: inView2} = useInView({
    triggerOnce: false,
    threshold: .0,
  });



  const [expanded, setExpanded] = useState(false);

  const text = "Our proven track record in SEO, Google Ads, and digital marketing ensures high ROI, increased brand visibility, and sustained business growth.";

  const words = text.split(" ");
  const shortText = words.slice(0, 17).join(" ") + (words.length > 20 ? "..." : "");

  const [expanded2, setExpanded2] = useState(false);

  const text2 = "At BHUMU, our mission is to help businesses thrive in the digital world. Through expert digital marketing, SEO, Google Ads, and custom web & app development, we drive growth, increase visibility, and maximize ROI, ensuring long-term success for our clients";

  const words2 = text.split(" ");
  const shortText2 = words.slice(0, 17).join(" ") + (words.length > 20 ? "..." : "");


  return (
    <div  className="main-parent-bussness-success-rate-container"
    
    // ${ inView1 ? "fade-up-swing":"fade-up-animation-opacity"} `}
    >
      <div className="inner-bussness-success-rate-container"
      
     

      >
        <div  ref={ref1} className={`child-bussness-success-rate-container
        
         ${ inView1 ? "fade-up-fade-up":"fade-up-animation-opacity"} `}
        >
          <div className="row1">
            <div className="success-tag-container  bb">
              <div className="iconss">
              <i class="fa-solid fa-check"></i>
              </div>
              <div className="success-tag-textt">
                <p className="p1"> Highest Success Rates</p>

                {/* <p className="p2">
                Our proven track record in SEO, Google Ads, and digital marketing ensures high ROI, increased brand visibility, and sustained business growth.
                </p> */}

{/* <div> */}
      <p className="p2">
        {expanded ? text : shortText}
        {words.length >17 && (<span  onClick={() => setExpanded(!expanded)}> {expanded ? "Show Less" : "Show More"}  </span> )}

        
      </p>
   
    {/* </div> */}
              </div>
              
            </div>


            <div className="success-tag-container ">
              <div className="iconss">
              <i class="fa-solid fa-check"></i>
              </div>
              <div className="success-tag-textt">
                <p className="p1">We Grow Businesses</p>

                <p className="p2">


                {expanded2 ? text2 : shortText2}
        {words2.length >17 && (<span  onClick={() => setExpanded2(!expanded2)}> {expanded2 ? "Show Less" : "Show More"}  </span> )}

        
               
                </p>
              </div>
            </div>


          </div>
          <div className="right-success-rate-container">
            


            <div className="inner-right-sucessr-rate-image">

            <div className="textt">
              
              <div>
              <i class="fa-solid fa-arrow-up-right-dots"></i>
              </div>
              <div> We have 6 years of exprence </div>
              
              </div>
            <div className="buness-img">
                <img src={bussnessimh}></img>
            </div>
            </div>
            </div>
        </div>

            <div  ref={ref2} className={`
            
            
            ${ inView2 ? "fade-up-fade-up":"fade-up-animation-opacity"} `}

            >
   
        </div>
      
      </div>
      <BussnessTag />
 
    </div>
  );
}

export default BussnessSuccessShow;
