import React from "react";
import project1 from "../../images/project-1-1.jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import { useInView } from "react-intersection-observer";

// import  Glazma from "../../images/newglazma.png"
// import  Repairbody  from "../../images/newrepairboddy.png"
// import  garageonImg from "../../images/newgrazoncall.png"
// import  fluffytouch from "../../images/newfollpytouch.png"
// import  tattoowali from "../../images/newtattuproject.png"
// import  feedbroker from "../../images/feedbrokerproject.png"
// import lollegends from "../../images/lollegproject.png"


import GOC from "../../media/images/company-project/GOC.png";
import FT from "../../media/images/company-project/FT.png";
import GMS from "../../media/images/company-project/GMS.png";
import RB from "../../media/images/company-project/RB.png";
import FB from "../../media/images/company-project/FB.png";
import TW from "../../media/images/company-project/TW.png";
import LOL from "../../media/images/company-project/LOL.png";

import GOCApp from "../../media/images/company-project/GOCApp.jpg"
import RBApp from "../../media/images/company-project/RBApp.jpg"
import FTApp from "../../media/images/company-project/FTApp.jpg"





function ExploreBestProject() {
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: false,
    threshold: 0.8,
  });

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 900, // When the screen width is less than 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // When the screen width is less than 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="best-project-container">
      <div className="inner-best-project-container">
        <div className="best-project-top-text-container">
          <div className="sm-text-project">
            <i class="fa-solid fa-chevron-left"></i>
            <i class="fa-solid fa-chevron-left"></i>
            <i class="fa-solid fa-chevron-left"></i> our recent work
            <i class="fa-solid fa-chevron-right"></i>
            <i class="fa-solid fa-chevron-right"></i>
            <i class="fa-solid fa-chevron-right"></i>
          </div>
          <div
            ref={ref1}
            className={`headline-project
          
            ${inView1 ? "flip-y" : "fade-up-animation-opacity"}`}
          >
            Explore Our Best Recent <br /> Work Projects
          </div>
        </div>
        <div className="best-project-image-container">
          <div className="inner-best-project-image-container">
            <Slider {...settings}>

                <Link  to="">
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={GOC} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>Bike Repairing</span>
                      </div>

                      <Link to="/project-details">
                        <div className="tech-text">
                          Garage On  Call
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              </Link>

              <Link to="">
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={RB} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>AC Servicing </span>
                      </div>

                      <Link to="/project-details">
                        <div className="tech-text">
                        Repairbuddy<br /> 
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              
              <Link to="">
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={FT} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>Pet Grooming </span>
                      </div>

                      <Link to="/project-details">
                        <div className="tech-text">
                        Fluffytouch <br />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              </Link>

              <Link to="">
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={FB} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>Social Media </span>
                      </div>

                      <Link to="/project-details">
                        <div className="tech-text">
                        Feed Backer <br /> 
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div> 
              </Link>

              <Link to="">
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={GMS} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>Men's Sallon </span>
                      </div>

                      <Link to="/project-details">
                        <div className="tech-text">
                        Glazma <br />      
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              </Link>

             
              <Link to=""> 
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={TW} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>Tattoo Company</span>
                      </div>

                      <Link to="/project-details">
                        <div className="tech-text">
                        TattooWali <br /> 
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              </Link>



              <Link to=""> 
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={LOL} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>Meme Agency </span>
                      </div>

                      <Link to="/project-details">
                        <div className="tech-text">
                        The Meme Agency<br /> 
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              </Link>


              <Link to=""> 
              
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={GOCApp} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>   Bike Repairing App  </span>
                      </div>

                      <Link to="/project-details">
                     <div className="tech-text">
                     Garage On  Call 
                       <br /> 
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              </Link>

              <Link to=""> 
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={FTApp} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>   Pet Grooming App </span>
                      </div>

                      <Link to="/project-details">
                        <div className="tech-text">
                        Fluffytouch <br /> 
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              <Link to=""> 
              <div className="outer-project-card">
                <div className="project-card">
                  <div className="best-project-img">
                    <img src={RBApp} />
                  </div>
                  <div className="">
                    <div className="bggee">
                      <div>
                        {" "}
                        <span>    AC Servicing App </span>
                      </div>

                      <Link to="/project-details">
                        <div className="tech-text">
                        Repairbuddy <br /> 
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              </Link>

            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreBestProject;
