import React from 'react'
import {Link} from "react-router-dom";

function Footerpolicy() {
  return (
    <div className="footer-policy-container">


        <div className="footer-policy-main-container">

            <div className="inner-footer-policy-container">


                <div className="left-footer-policy-bottom"> Copyright © 2025 <span>BHUMU.</span> All Rights Reserved.</div>
                <div className="right-footer-policy-bottom"> <div> <Link to="/">  Privacy Policy  </Link> </div>  <div> <Link to="/"> Terms & Condition  </Link></div></div>
            </div>


        </div>
      
    </div>
  )
}

export default Footerpolicy
