import React from 'react';


function MarketingScrollTag() {
  return (
    <div className="marketing-tagscoll-container">
      <div className="out-bg-marketing-tagscoll-container">
        <div className="inner-marketing-tagscoll-container">
          <div className="marketing-tag-content-container">
            <div className="scroll-items">
              <span>Marketing Solution</span>
              <span>Marketing Solution</span>
              <span>Marketing Solution</span>
              <span>Marketing Solution</span>
              <span>Marketing Solution</span> {/* Duplicate for seamless scroll */}
              <span>Marketing Solution</span>
              <span>Marketing Solution</span>
              <span>Marketing Solution</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketingScrollTag;
