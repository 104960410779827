import { useState } from "react"; 
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";


// import clintimg from "../images/clientimg.png";
import clintimg from "../../images/clientimg.png"
function BestAgencycard() {
  const { ref, inView } = useInView({
    triggerOnce: false, 
    threshold: .0, 
  });


  const { ref:ref1, inView:inView1 } = useInView({
    triggerOnce: false, 
    threshold: .0, 
  });
  
  const { ref:ref2, inView:inView2 } = useInView({
    triggerOnce: false, 
    threshold: .0, 
  });

  const { ref:ref3, inView:inView3 } = useInView({
    triggerOnce: false, 
    threshold: .0, 
  });



    const [expanded, setExpanded] = useState(false);
  
    const text = "In today’s fast-paced digital world, businesses need a strong online presence to stand out and thrive. At BHUMU, we are committed to providing best digital marketing solutions tailored to your business needs. As the best digital marketing agency in Noida and Delhi NCR, we specialize in SEO, Google Ads, Meta Ads, website development, app development, and branding services to help businesses grow exponentially.";
  
    const words = text.split(" ");
    const shortText = words.slice(0, 20).join(" ") + (words.length > 20 ? "..." : "");
  
  return (
    <div className="best-main-agency-card">
      <div className="inner-best-agency">
        <div  ref={ref3} className={`agency-client-img
        
        
        ${ inView3 ? "fade-up-swing1":"fade-up-animation-opacity"}`}

        
        >
          <img src={clintimg}></img>
        </div>


        {/* <div 
          // ref={ref}
        className={`agency-right-side-text-container${
          inView ? "agency-right-side-text-container-animation" : "agency-right-side-text-container-opacity"
        }`}
        > */}
        <div className="agency-right-side-text-container">


          <div ref={ref} className={`agency-right-text
          
           ${ inView ? "fade-right":"fade-up-animation-opacity"}`}
          >
            <p className="p1"> 
               <i class="fa-solid fa-chevron-left"></i> <i class="fa-solid fa-chevron-left"></i> <i class="fa-solid fa-chevron-left"></i>get to know us    <i class="fa-solid fa-chevron-right"></i>  <i class="fa-solid fa-chevron-right"></i>  <i class="fa-solid fa-chevron-right"></i> </p>
            <p className="p2 ">The Best Digital Marketing Agency in India</p>

            <p className="p3">
            We’re Committed to Deliver Best Digital Marketing Services 
            </p>
            <p className="p4">
              
              
            {expanded ? text : shortText}
        {words.length >20 && (<span  onClick={() => setExpanded(!expanded)}> {expanded ? "Show Less" : "Show More"}  </span> )}

        
            </p>
          </div>

          <div ref={ref1} className={`agency-right-middle-text
          
          
          ${ inView1 ? "fade-right":"fade-up-animation-opacity"}`}

          >
            <div className="right-text-col1">
              <div>
                <i class="fa-solid fa-arrow-down"></i> Design Freedom
              </div>
              <p>creative flexibility and innovation in web, app, and digital marketing solutions. </p>
            </div>
            <div className="right-text-col1">
              <div>
                {" "}
                <i class="fa-solid fa-arrow-down"></i> Marketing Rules
              </div>
              <p>Marketing Rules for Success to highlight BHUMU's strategic approach to digital marketing.</p>
            </div>
          </div>

          <div  ref={ref2}  className={`

             ${ inView2 ? "fade-right":"fade-up-animation-opacity"}`}
          >

          <div className="processbar-main-container">
            <div class="progress-container">
              <div class="progress-label">
                <span>Marketing</span>
                <span class="progress-value">95%</span>
              </div>
              <div class="progress-bar">
                <div class="progress-fill"></div>
              </div>
            </div>
          </div>

          <div>
            <Link  t to="https://wa.me/9658139139?text=Hi, I'm interested in Bhumu Digital Services. Please contact me."
          target="blank">
            <button className="agency-right-desicover-more">
              Discover more
            </button>
            </Link>
          </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BestAgencycard;
