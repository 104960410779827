import React, {useEffect} from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import { Link, useNavigate } from "react-router-dom";
function ErrorPage() {

  const navigate = useNavigate()

  useEffect(() => {
   
    navigate("/")
  }, [])
  
  
  return (
    <div className="error-page-main-container">
      <BreadCrumb path="404-Error" heading="404-Error" />

      <div className="inner-error-page-main-container">
  

        <div>
          <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/shapes/error-page-shape-1.png"></img>
        </div>

        <p>Oops! Page Not Found</p>
        <p> The page you are looking for is not exist.</p>

        <div className="error-page-btn">
          <Link to="/">
            <button >Back to Home</button>
          </Link>
        </div>
      
      </div>
    </div>
  );
}

export default ErrorPage;
