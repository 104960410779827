import React from "react";
import { useInView } from "react-intersection-observer";

import imgcard from "../../images/Screenshot 2025-02-21 173038.png";
import tagIcon1 from "../../media/images/icons/TagIcon2.png";
import tagIcon2 from "../../media/images/icons/TagIcon3.png";
import tagIcon3 from "../../media/images/icons/TagIcon1.png";
import { Link } from "react-router-dom";

function AgencyTagImageContainer() {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: false,
    threshold: 0,
  });

  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: false,
    threshold: 0,
  });

  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: false,
    threshold: 0,
  });

  const { ref: ref5, inView: inView5 } = useInView({
    triggerOnce: false,
    threshold: 0,
  });
  return (
    <>
      {/* Top-agency tang image */}

      <div className="Top-agency-wallpaer-image">
        {/* <div className="inner-Top-agency-wallpaer-image">
          <div
            ref={ref}
            className={`top-wall-agency--image-container ${
              inView
                ? "top-wall-agency--image-container-animation"
                : "top-wall-agency--image-container-opacity"
            }`}
          >
            <div className="shadow-top-wall-agency--image"></div>
          </div>
        </div> */}

        <div className="main-bttom-agency-image ">
          {/* <div  ref={ref2}  className={`main-bttom-agency-image ${
          inView2 ? "main-bttom-agency-image-animation":"main-bttom-agency-image-opacity"
        }`}> */}
          <div className="inner-bottom-agency-image">
            <div
              ref={ref5}
              className={`genency-text-shap-container ${
                inView5 ? "card-left-animation1" : "fade-up-animation-opacity"
              }`}
            >
              <div>
                <div className="hexagon22">
                  <div className="hexagon22-shadow">
                    <div className="icon-containers">
                      <img src={tagIcon1}></img>
                    </div>
                  </div>
                </div>
                <div className="texttt">
                  <p>Thinking Differently</p>
                </div>
              </div>

              <div className="inner-bottom-agency-image-hover inner-agency-bg11">
               
                <div className="hexagon22-shadow">
                  <div>
                    <div className="icon-containers-hover">
                   
                        <img src={tagIcon1}></img>
                
                    </div>
                  </div>
                  <div>Thinking Differently</div>
                </div>

              </div>
            </div>

            <div
              ref={ref4}
              className={`genency-text-shap-container ${
                inView4 ? "card-left-animation2" : "fade-up-animation-opacity"
              }`}
            >
              <div>
                <div className="hexagon221">
                  <div className="hexagon22-shadow">
                    <div className="icon-containers">
                    <img src={tagIcon2}></img>
                    </div>
                  </div>
                </div>
                <div className="texttt">
                  <p> High Quality Projects</p>
                </div>
              </div>

              <div className="inner-bottom-agency-image-hover inner-agency-bg22">
              <div className="hexagon22-shadow">
                  <div>
                    <div className="icon-containers-hover">
                   
                        <img src={tagIcon2}></img>
                
                    </div>
                  </div>
                  <div>High Quality Projects</div>
                </div>
              </div>
            </div>

            <div
              ref={ref3}
              className={`genency-text-shap-container

${inView3 ? "card-left-animation3" : "fade-up-animation-opacity"}`}
            >
              <div>
                <div className="hexagon222">
                  <div className="hexagon22-shadow">
                    <div className="icon-containers">
                    <img src={tagIcon3}></img>
                    </div>
                  </div>
                </div>
                <div className="texttt">
                  <p> Smart Team Staff</p>
                </div>
              </div>

              <div className="inner-bottom-agency-image-hover inner-agency-bg33">
              <div className="hexagon22-shadow">
                  <div>
                    <div className="icon-containers-hover">
                   
                        <img src={tagIcon3}></img>
                
                    </div>
                  </div>
                  <div>Smart Team Staff</div>
                </div>
              </div>
            </div>
          </div>

    
        </div>
   
      </div>


<div className="Outer-bottom-agency-image-input-container">
  <div className="shadow--bottom-agency-image-input-container">

      <div className="inner-bottom-agency-image-input-container">
            <div class="input-container">
              <input
                type="text"
                placeholder="Digital agency services built specifically for your business. "
              />
              <Link to="https://wa.me/9658139139?text=Hi, I'm interested in Bhumu Digital Services. Please contact me."   target="blank">
              <button>Contact Now</button>
              </Link>
            </div>
          </div>
          </div>
          </div>
    </>
  );
}

export default AgencyTagImageContainer;
