import React, { useEffect } from 'react'
import LoadingComponent from './LoadingComponent';

const CpanelPage = () => {

    useEffect(() => {
        window.location.href = 'https://p3plzcpnl504710.prod.phx3.secureserver.net:2083/';
    }, []); 

    
  return <>
  <LoadingComponent/>
  </>
}

export default CpanelPage