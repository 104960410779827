import React from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import { Link } from "react-router-dom";

function BlogDetailsPage() {
  return (
    <div className="blog-details-main-container">
      <BreadCrumb path="Blog-details" heading="Blog-details" />

      <div className="inner-blog-details-container">
        <div className="inner-blog-details-left-container">
          <div className="inner-blog-details-top-images">
            <div className="artical-img-container">
              <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/blog/blog-details-img-1.jpg" />
              <div className="dddd">
                <div className="month">
                  <span className="day">24</span>{" "}
                  <span className="year">Fab 2025</span>
                </div>
              </div>
            </div>

            <div className="blog-details-all-text-container">
              <p>Get a few solutions to hire a best candidate</p>

              <p>
                There are many variations of passages of Lorem Ipsum available,
                but majority have suffered alteration in some form, by injected
                humour, or randomised words which don't look even slightly
                believable. If you are going to use a passage of Lorem Ipsum.
                There are many variations of passages of Lorem Ipsum available,
                but majority have suffered alteration in some form, by injected
                humour, or randomised words which don't look even slightly
                believable. If you are going to use a passage of Lorem Ipsum.
                There are many variations of passages of Lorem Ipsum available,
                but majority have suffered alteration in some form, by injected
                humour, or randomised words which don't look even slightly
                believable. If you are going to use a passage of Lorem Ipsum.
                Suspendisse ultricies vestibulum vehicula. Proin laoreet
                porttitor lacus. Duis auctor vel ex eu elementum. Fusce eu
                volutpat felis. Proin sed eros tincidunt, sagittis sapien eu,
                porta diam. Aenean finibus scelerisque nulla non facilisis.
                Fusce vel orci sed quam gravida condimentum. Aliquam
                condimentum, massa vel mollis volutpat, erat sem pharetra quam,
                ac mattis arcu elit non massa. Nam mollis nunc velit, vel varius
                arcu fringilla tristique. Cras elit nunc, sagittis eu bibendum
                eu, ultrices placerat sem. Praesent vitae metus dolor. Nulla a
                erat et orci mattis auctor.
              </p>

              <p>
                Mauris non dignissim purus, ac commodo diam. Donec sit amet
                lacinia nulla. Aliquam quis purus in justo pulvinar tempor.
                Aliquam tellus nulla, sollicitudin at euismod nec, feugiat at
                nisi. Quisque vitae odio nec lacus interdum tempus. Phasellus a
                rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque
                vitae et nunc. Sed vitae leo vitae nisl pellentesque semper.
              </p>
            </div>

            <div className="blog-details-bottom-left">
              <div className="blog-details-bottom-child-1">
                <div>
                  <span>Tags</span>
                  <button>Marketing</button>
                  <button> Digital</button>
                </div>
                <div className="">
                  <Link to="">
                    <i class="fa-brands fa-twitter"></i>
                  </Link>
                  <Link to="">
                    <i class="fa-brands fa-facebook"></i>
                  </Link>
                  <Link to="">
                    <i class="fa-brands fa-pinterest"></i>
                  </Link>

                  <Link to="">
                    <i class="fa-brands fa-instagram"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-details-tag-card-container">
                <div className="blog-details-tag-card">
                  <div>
                    <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/blog/blog-details-pagenation-img-1.jpg"></img>
                  </div>
                  <div>
                    <p>
                      <i class="fa-solid fa-clock"></i> 06 March 2025{" "}
                    </p>
                    <p>Get deep knowledge of your audience</p>
                  </div>
                </div>
                <div className="blog-details-tag-card">
                  <div>
                    <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/blog/blog-details-pagenation-img-1.jpg"></img>
                  </div>
                  <div>
                    <p>
                      <i class="fa-solid fa-clock"></i> 06 March 2025{" "}
                    </p>
                    <p>Get deep knowledge of your audience</p>
                  </div>
                </div>
              </div>

              <p className="commentt-text">2 Comments</p>
              <div className="blog-commnet-profile">
                <div>
                  <img
                    src="https://pitoon.pixcelsthemes.com/main-html/assets/images/blog/comment-1-1.jpg"
                    alt="images"
                  ></img>
                </div>
                <div>
                  <p>
                    Kevin Martin<button>reply</button>
                  </p>
                  <p>
                    Mauris non dignissim purus, ac commodo diam. Donec sit amet
                    lacinia nulla. Aliquam quis purus in justo pulvinar tempor.
                    Aliquam tellus nulla, sollicitudin at euismod.
                  </p>{" "}
                </div>
              </div>

              <div className="blog-commnet-profile">
                <div>
                  <img
                    src="https://pitoon.pixcelsthemes.com/main-html/assets/images/blog/comment-1-1.jpg"
                    alt="images"
                  ></img>
                </div>
                <div>
                  <p>
                    Kevin Martin<button>reply</button>
                  </p>
                  <p>
                    Mauris non dignissim purus, ac commodo diam. Donec sit amet
                    lacinia nulla. Aliquam quis purus in justo pulvinar tempor.
                    Aliquam tellus nulla, sollicitudin at euismod.
                  </p>{" "}
                </div>
              </div>

              <div className="log-details-comment-container">
                <p>Leave a comment</p>

                <div className="blog-details-input-box">
                  <div>
                    <input type="text" placeholder="Your name"></input>
                  </div>
                  <div>
                    <input type="text" placeholder="email"></input>
                  </div>
                </div>
                <div>
                  <textarea placeholder="write a comment"></textarea>
                </div>
                <div>
                  <button className="blog-details-submit-commnet-btn">
                    Submit Comment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Bblog-details-right-mian-conatiner">
          <div className="bolog-detail-right-top-input">
            <div>
              <input type="text" placeholder="keywords..."></input>
              <button>
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
          </div>

          <div className="blog-details-lattest-project">
            <div className="inner-blog-details-lattest-project">
              <p>Projects</p>

              <div className="inner-blog-details-lattest-card">
                <div>
                  <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/blog/lp-1-1.jpg"></img>
                </div>
                <div>
                  <p>
                    {" "}
                    <i class="fa-solid fa-clock"></i> 06 March 2025
                  </p>
                  <p>
                    <Link to="">Get deep knowledge of your audience</Link>
                  </p>
                </div>
              </div>

              <div className="inner-blog-details-lattest-card">
                <div>
                  <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/blog/lp-1-1.jpg"></img>
                </div>
                <div>
                  <p>
                    {" "}
                    <i class="fa-solid fa-clock"></i> 06 March 2025
                  </p>
                  <p>
                    <Link to="">Get deep knowledge of your audience</Link>
                  </p>
                </div>
              </div>
              <div className="inner-blog-details-lattest-card">
                <div>
                  <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/blog/lp-1-1.jpg"></img>
                </div>
                <div>
                  <p>
                    {" "}
                    <i class="fa-solid fa-clock"></i> 06 March 2025
                  </p>
                  <p>
                    <Link to="">Get deep knowledge of your audience</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="blog-details-category-container">
            <p>Categories</p>

            <div>
              <p>
                {" "}
                <i class="fa-solid fa-caret-right"></i>{" "}
                <Link to="">Web Cevelopment</Link>
              </p>
            </div>

            <div>
              <p>
                {" "}
                <i class="fa-solid fa-caret-right"></i>{" "}
                <Link to="">Social Marketing</Link>
              </p>
            </div>

            <div>
              <p>
                {" "}
                <i class="fa-solid fa-caret-right"></i>{" "}
                <Link to="">Technology</Link>
              </p>
            </div>

            <div>
              <p>
                {" "}
                <i class="fa-solid fa-caret-right"></i>{" "}
                <Link to="">Digital Solutions</Link>
              </p>
            </div>

            <div>
              <p>
                {" "}
                <i class="fa-solid fa-caret-right"></i>{" "}
                <Link to="">Busness & Finance</Link>
              </p>
            </div>
          </div>

          <div className="blog-details-project-slider">
            <p>Projects</p>

            <div>
              <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/backgrounds/sidebar-project-bg.jpg"></img>
            </div>
          </div>
          <div className="blog-details-tag-container">
            <p>Tags</p>
            <div>
              <button>Marketing</button>
              <button>Development</button>
              <button>Consulting</button>
              <button>Digital</button>
            </div>
          </div>

          <div className="blog-details-comments-container">
            <p>Comments</p>
            <div className="blog-details-comment-icon">
              <div>
                <i class="fa-solid fa-comment"></i>
              </div>
              <div>sit amet consectetur adipisicing elit.</div>
            </div>


            <div className="blog-details-comment-icon">
              <div>
                <i class="fa-solid fa-comment"></i>
              </div>
              <div>sit amet consectetur adipisicing elit.</div>
            </div>



            <div className="blog-details-comment-icon">
              <div>
                <i class="fa-solid fa-comment"></i>
              </div>
              <div>sit amet consectetur adipisicing elit.</div>
            </div>

            <div className="blog-details-comment-icon">
              <div>
                <i class="fa-solid fa-comment"></i>
              </div>
              <div>sit amet consectetur adipisicing elit.</div>
            </div>


         
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetailsPage;
