import React from "react";
import { Link } from "react-router-dom";
import DigitalCard1 from "./DigitalCard1";
import { useInView } from "react-intersection-observer";

function Digitalcard() {
  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: .1, 
  });


  const { ref2, inView2 } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: .3, // Animation triggers when 20% of the component is visible
  });
  return (
    <>
      <div
          className="customer-cards-main-container"
          >
        <div 
          ref={ref}
        className={`set-top-card-container ${
          inView ? "set-top-card-container-animation" : "set-top-card-container-opacity"
        }`}
        >
          <DigitalCard1 />
        </div>

        <div className="shadow-customer-cards-main-container">
          <div className="shaw-2">
            <div ref={ref2}
            
            
            className={`inner-card-container ${
              inView2 ? "inner-card-container-animation" : ""
            }`}
            >
              <div className="card-conainer-text">

              Thousands of customers trust Our <br/> Digital Marketing Services
                {/* Hundread of customer trust<br /> Our Digital Agency */}
              </div>

              <div>
                <Link    to="https://wa.me/9658139139?text=Hi, I'm interested in Bhumu Digital Services. Please contact me."
          target="blank">
                  <div className="btn-get-free-qurts">Get Free Quote</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Digitalcard;
