import React from "react";
import { Link } from "react-router-dom";
function WhyusHome() {
  return (
    <div className="why-us-main-container">
      <div className="inner-why-us-home-container">
        <div className="why-top-container-bg">
          <div className="hire-us">
            <div className="why-you-text"> Why You Should </div>
            <div className="hire-us-text"> Hire Us </div>
            <div className="hire-us-border"></div>
          </div>
        </div>
      </div>

      {/* why us bootm conyainer */}

      <div className="why-us-bottom-tag-container">
        <div>
          {" "}
          <div>
            <i class="fa-solid fa-check"></i>
          </div>{" "}
          Free Digital Transformation

        </div>

        <div>
          {" "}
          <div>
            <i class="fa-solid fa-check"></i>
          </div>
          Expert Digital Marketing 

        </div>

        <div>
          {" "}
          <div>
            <i class="fa-solid fa-check"></i>
          </div>
          Complete Business Solutions 

        </div>

        <div>
          {" "}
          <div>
            <i class="fa-solid fa-check"></i>
          </div>
          Proven Results & Experience 

        </div>

        <div>
          {" "}
          <div>
            <i class="fa-solid fa-check"></i>
          </div>
          Ground-Level Research & Support
        </div>

        <div>
          {" "}
          <div>
            <i class="fa-solid fa-check"></i>
          </div>{" "}
          Customized Growth Strategies

        </div>




        <div>
          {" "}
          <div>
            <i class="fa-solid fa-check"></i>
          </div>{" "}
          Real-Time Performance Tracking


        </div>



        <div>
          {" "}
          <div>
            <i class="fa-solid fa-check"></i>
          </div>{" "}
          Personalized Support & Consultancy

        </div>

        <div>
          {" "}
          <div>
            <i class="fa-solid fa-check"></i>
          </div>{" "}
          Fast & Reliable Execution


        </div>

      </div>
      <div className="our-patnerslogo-heading2">
        <div class="patner-logo-line2"></div>

        <Link
          to="https://wa.me/9658139139?text=Hi, I'm interested in Bhumu Digital Services. Please contact me."
          target="blank"
        >
          {" "}
          <div className="agency-right-desicover-more  our-patnerslogo-heading2 ">
            {" "}
            Discover More{" "}
          </div>
        </Link>
        <div class="patner-logo-line2"></div>
      </div>
    </div>
  );
}

export default WhyusHome;
