import React from "react";
import Slider from "react-slick";

function UserReviewCard() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 768, // When the screen width is less than 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="user-review-mian-container">
      <div className="review-heading-container">
        <div className="inner-review-heading-container">
          
        <p className="p1">
        <i class="fa-solid fa-chevron-left"></i>
        <i class="fa-solid fa-chevron-left"></i>
        <i class="fa-solid fa-chevron-left"></i>
        our testimonials

        <i class="fa-solid fa-chevron-right"></i>
        <i class="fa-solid fa-chevron-right"></i>
        <i class="fa-solid fa-chevron-right"></i>
          
          </p>
          <p className="p2">What They’re Saying?</p>
        </div>
      </div>

      <div className="inner-user-review-mian-container">
        <Slider {...settings}>
          <div className="review-shadow">
          <div className="card-review">
            <div className="inner-card-review">
              <div>
                <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-2.jpg"></img>
              </div>
              <div>
                <p>User-name</p>
                <p>
                  <i class="fa-solid fa-star"></i>{" "}
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </p>
              </div>
            </div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Perferendis, blanditiis!
            </div>
          </div>
          </div>

   


          <div className="review-shadow">
          <div className="card-review">
            <div className="inner-card-review">
              <div>
                <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-2.jpg"></img>
              </div>
              <div>
                <p>User-name</p>
                <p>
                  <i class="fa-solid fa-star"></i>{" "}
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </p>
              </div>
            </div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Perferendis, blanditiis!
            </div>
          </div>
          </div>

          <div className="review-shadow">
          <div className="card-review">
            <div className="inner-card-review">
              <div>
                <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-2.jpg"></img>
              </div>
              <div>
                <p>User-name</p>
                <p>
                  <i class="fa-solid fa-star"></i>{" "}
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </p>
              </div>
            </div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Perferendis, blanditiis!
            </div>
          </div>
          </div>

          <div className="review-shadow">
          <div className="card-review">
            <div className="inner-card-review">
              <div>
                <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-2.jpg"></img>
              </div>
              <div>
                <p>User-name</p>
                <p>
                  <i class="fa-solid fa-star"></i>{" "}
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </p>
              </div>
            </div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Perferendis, blanditiis!
            </div>
          </div>
          </div>

          <div className="review-shadow">
          <div className="card-review">
            <div className="inner-card-review">
              <div>
                <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-2.jpg"></img>
              </div>
              <div>
                <p>User-name</p>
                <p>
                  <i class="fa-solid fa-star"></i>{" "}
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </p>
              </div>
            </div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Perferendis, blanditiis!
            </div>
          </div>
          </div>
          {/* 
          <div className="user-review-card-left">
          <div className="user-profile-img">
            <div>
              <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-3.jpg"></img>{" "}
            </div>
          </div>

          <div className="user-review-text">
            <p className="user-nm">User Name</p>

            <p>
              <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </p>

            <p className="user-desc">
              {" "}
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
              quasi odit explicabo facilis a tempora maiores necessitatibus odio
              ullam culpa?
            </p>
          </div>
        </div> */}

          {/*           
    


        <div className="user-review-card-left">
          <div className="user-profile-img">
            <div>
              <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-3.jpg"></img>{" "}
            </div>
          </div>

          <div className="user-review-text">
            <p className="user-nm">User Name</p>

            <p>
              <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </p>

            <p className="user-desc">
              {" "}
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
              quasi odit explicabo facilis a tempora maiores necessitatibus odio
              ullam culpa?
            </p>
          </div>
        </div>


        <div className="user-review-card-left">
          <div className="user-profile-img">
            <div>
              <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-3.jpg"></img>{" "}
            </div>
          </div>

          <div className="user-review-text">
            <p className="user-nm">User Name</p>

            <p>
              <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </p>

            <p className="user-desc">
              {" "}
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
              quasi odit explicabo facilis a tempora maiores necessitatibus odio
              ullam culpa?
            </p>
          </div>
        </div>


        <div className="user-review-card-left">
          <div className="user-profile-img">
            <div>
              <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-3.jpg"></img>{" "}
            </div>
          </div>

          <div className="user-review-text">
            <p className="user-nm">User Name</p>

            <p>
              <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </p>

            <p className="user-desc">
              {" "}
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
              quasi odit explicabo facilis a tempora maiores necessitatibus odio
              ullam culpa?
            </p>
          </div>
        </div>


        <div className="user-review-card-left">
          <div className="user-profile-img">
            <div>
              <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-3.jpg"></img>{" "}
            </div>
          </div>

          <div className="user-review-text">
            <p className="user-nm">User Name</p>

            <p>
              <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </p>

            <p className="user-desc">
              {" "}
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
              quasi odit explicabo facilis a tempora maiores necessitatibus odio
              ullam culpa?
            </p>
          </div>
        </div>


        <div className="user-review-card-left">
          <div className="user-profile-img">
            <div>
              <img src="https://pitoon.pixcelsthemes.com/main-html/assets/images/testimonial/testimonial-1-3.jpg"></img>{" "}
            </div>
          </div>

          <div className="user-review-text">
            <p className="user-nm">User Name</p>

            <p>
              <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </p>

            <p className="user-desc">
              {" "}
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
              quasi odit explicabo facilis a tempora maiores necessitatibus odio
              ullam culpa?
            </p>
          </div>
        </div> */}
        </Slider>
      </div>
    </div>
  );
}

export default UserReviewCard;
