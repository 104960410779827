import React from 'react'
import{ Link} from "react-router-dom"
import Animation from './Animation'

function BreadCrumb({path, heading}) {
  return (
    <div>
        
    <div className="BreadCom-main-container">
     
      <div className="Inner-BreadCom-main-container">
        <div className="Text-BreadCom-main-container">
      
            <Link to="/">  Home</Link> {path?<> <i class="fa-solid fa-angle-right"></i> {path}</>:""}

            <div className='bread-com-header'><p>{heading&&heading}</p></div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default BreadCrumb
