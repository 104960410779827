import React from "react";

import blog1 from "../../images/blog1.jpg";
import blog2 from "../../images/blog2.jpg";
import blog3 from "../../images/blog3.jpg";
import admindp from "../../images/dgcardsm1.jpg"
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import LetsWorkTogether from "./LetsWorkTogether";
function LatestArtical() {
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: false,
    threshold: 0.0,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: false,
    threshold: 0.0,
  });
  return (
    <div className="lattest-artical-main-container">
      <div className="inner-lattest-artical-main-container">
        <div className="artical-heading-container">
           <div className="p1">
            <i class="fa-solid fa-chevron-left"></i>
            <i class="fa-solid fa-chevron-left"></i>
            <i class="fa-solid fa-chevron-left"></i>
            FROM THE BLOG
            <i class="fa-solid fa-chevron-right"></i>
            <i class="fa-solid fa-chevron-right"></i>
            <i class="fa-solid fa-chevron-right"></i>
          </div> 
          <div className="p2">
            Latest News & <br />
            Articles from the Blog
          </div>
        </div>


<div className="lattest-artical-blog-over-flow-container">
        <div ref={ref1} className={`artical-card-image-container
        
        
        ${
          inView1 ? "fade-up-fade-upOneS" :"fade-up-animation-opacity "
        }`}
        
        >



          <div>
            <div className="artical-img-container">
              <img src={blog1} />
              <div className="dddd">
                {" "}
                <div className="month">
                  <span className="day">20</span>{" "}
                  <span className="year"> March 2025</span>
                </div>
              </div>
            </div>
            <div className="artical-profile-text-container ">
              <div className="inner-artical-profile-text-container">
                <div className="artical-profile-img-container">
                  <img
                  src={admindp}
                    alt="profile"
                  />
                  <div>
                    <p>by</p>
                    <p>Admin</p>
                  </div>
                </div>

                <p className="artical-head">
                 How Small Businesses Can Leverage Digital Marketing to Maximize Their Growth
                </p>
                <p className="artical-desc">
                Small businesses now have a unique opportunity to compete with large corporations through digital marketing. 
                </p>

                <Link to="/business-blog" target="_blank">
                  <div className="artical-card-bottom-container">
                    <div>Read More</div>
                    <div>
                      <i class="fa-solid fa-caret-right"></i>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>



          <div>
            <div className="artical-img-container">
              <img src={blog2} />
              <div className="dddd">
                {" "}
                <div className="month">
                  <span className="day">20</span>{" "}
                  <span className="year"> March 2025</span>
                </div>
              </div>
            </div>
            <div className="artical-profile-text-container">
              <div className="inner-artical-profile-text-container">
                <div className="artical-profile-img-container">
                  <img
                  src={admindp}
                    alt="profile"
                  />

                  <div>
                    <p>by</p>
                    <p>Admin</p>
                  </div>

                  {/* <span> by Sudhir </span> */}
                </div>

                <p className="artical-head">
                The Importance of Local SEO in Dominating Your Market: A Complete Guide
                </p>
                <p className="artical-desc">
              In today's digital world, consumers heavily rely on search engines to discover local businesses
                </p>
                <Link to="/seo-blog" target="_blank">
                  <div className="artical-card-bottom-container">
                    <div>Read More</div>
                    <div>
                      <i class="fa-solid fa-caret-right"></i>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>


          <div>
            <div className="artical-img-container">
              <img src={blog3} />
              <div className="dddd">
                {" "}
                <div className="month">
                  <span className="day">20</span>{" "}
                  <span className="year">March 2025</span>
                </div>
              </div>
            </div>

            <div className="artical-profile-text-container">
              <div className="inner-artical-profile-text-container">
                <div className="artical-profile-img-container">
                  <img

                  src={admindp}
                    alt="profile"
                  />
                  <div>
                    <p>by</p>
                    <p>Admin</p>
                  </div>
                </div>

                <p className="artical-head">
                Google Ads vs. Meta Ads: Which Gives Your Business a Better ROI?
                </p>
                <p className="artical-desc">
                When it comes to online advertising, businesses have two major platforms to choose from: Google Ads and Meta Ads. 
                </p>

                <Link to="/ads-blog" target="_blank">
                  <div className="artical-card-bottom-container">
                    <div>Read More</div>
                    <div>
                      <i class="fa-solid fa-caret-right"></i>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>





















        </div>
      
      
    
        </div>
      </div>

      <LetsWorkTogether />

      {/* <div className="Lets-work-together-main-container">

        <div className="inner-Lets-work-together-main-container">

            <div className="heading">
            Let’s work together just drop us a line</div>

            <div><button className="work-together-contact-btn">Contact Now</button></div>

        </div>
      </div> */}
    </div>
  );
}

export default LatestArtical;
