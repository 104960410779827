import React from "react";
import { Link } from "react-router-dom";
import bhumulogo from "../images/bhumu log.png"
function FooterNew() {
  return (
    <div className="newfooter-background">
      <div className="main-newfooter-container">
        <div className="inner-main-newfooter-container">
          <div>

            
            <div className="inner-child-new-footer-conatiner1">
              <div className="navbar-logo">
                <Link to="/">
                  {" "}
                  <img src={bhumulogo}></img>
                </Link>
              </div>

              <div>
              Welcome to our best digital agency, because it is pain, but occasionally circumstances.
              </div>

              <div className="inner-child-new-footer-links"> 

                <>

                <Link target="_blank" title="social media link" to="https://www.instagram.com/Bhumugroup/"><i className="fa-brands fa-instagram"></i></Link>
                <Link target="_blank" title="social media link" to="https://www.youtube.com/channel/UCeCCGpdtQ4TLKz8NAMkgR4A"><i className="fa-brands fa-youtube"></i></Link>
                <Link target="_blank" title="social media link" to="https://www.facebook.com/bhumugroup"><i className="fa-brands fa-facebook-f"></i></Link>
                <Link target="_blank" title="social media link" to="https://www.linkedin.com/company/bhumu-group-of-companies/?viewAsMember=true"><i className="fa-brands fa-linkedin-in"></i></Link>
                <Link target="_blank" title="social media link" to="https://twitter.com/GroupBhumu"><i className="fa-brands fa-x-twitter"></i></Link>
                
                </>
                
                
{/* <>
              <Link to="">
                  <i class="fa-brands fa-instagram"></i>
                </Link>

                <Link to="">
                  <i class="fa-brands fa-youtube"></i>
                </Link>
             
                <Link to="">
                  <i class="fa-brands fa-facebook"></i>
                </Link>
                <Link to="">
                  <i class="fa-brands fa-twitter"></i>
                </Link>
</> */}
               
              </div>
            </div>
            <div className="inner-child-new-footer-conatiner2">
              <p>Company</p>
              <div>
                <p>
                  {" "}
                  <Link to="">
                    {" "}
                    <i class="fa-solid fa-arrow-right"></i>About Us{" "}
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    <i class="fa-solid fa-arrow-right"></i>Our Services{" "}
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    <i class="fa-solid fa-arrow-right"></i>Our Blog{" "}
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    <i class="fa-solid fa-arrow-right"></i>Portfolio{" "}
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    {" "}
                    <i class="fa-solid fa-arrow-right"></i>Contact Us{" "}
                  </Link>
                </p>
              </div>
            </div>
            <div className="inner-child-new-footer-conatiner2">
              <p>Quick Links</p>
              <div>
                <p>
                  {" "}
                  <Link to="">
                    {" "}
                    <i class="fa-solid fa-arrow-right"></i>Terms & Conditions{" "}
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    <i class="fa-solid fa-arrow-right"></i>Forum Community
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    <i class="fa-solid fa-arrow-right"></i>Business Support
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    <i class="fa-solid fa-arrow-right"></i>Payment Gateway
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    {" "}
                    <i class="fa-solid fa-arrow-right"></i>Web Development
                  </Link>
                </p>
              </div>
            </div>
            <div className="inner-child-new-footer-conatiner2">
              <p>Get in Touch</p>
              <div>
                <p>
                  {" "}
                  <Link to="">
                    {" "}
                    <i class="fa-solid fa-arrow-right"></i>210, 2nd floor, Ocean Plaza, Sector 18 Noida 201301
                  </Link>
                </p>
                <p className="new-footer-number">
                  {" "}
                  <Link to="tel:9658-139-139" target="blank" >
                    <i class="fa-solid fa-arrow-right"></i>
                    +91 9658-139-139
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    <i class="fa-solid fa-arrow-right"></i>
                    support@bhumu.com
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="">
                    <i class="fa-solid fa-arrow-right"></i>10:00am - 6:00pm Mon-Sat
                  </Link>
                </p>
             
              </div>
        
         
          </div>



          </div>

      
     
        </div>
      </div>
    </div>
  );
}

export default FooterNew;
